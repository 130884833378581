import axios from './axiosConfig';

export async function fetchTicket(id) {
    try {
        const response = await axios.get(`/tik/${id}`);
        return response.data.tik;
    } catch (error) {
        console.error('Erreur lors de la recherche du ticket');
        throw error;
    }
}

export async function fetchTicketExtended(id) {
    try {
        const response = await axios.get(`/tik/extend/${id}`);
        return response.data.tik;
    } catch (error) {
        console.error('Erreur lors de la recherche du ticket');
        throw error;
    }
}

export async function fetchTickets(offset = 0, limit = 50, orderBy, orderDirection, CLI_id, ETA_id, EPCI_id, USR_id, DOM_id, search) {
    try {
        const response = await axios.get('/tik/', {
            params: {
                offset,
                limit,
                orderBy,
                orderDirection,
                CLI_id,
                ETA_id,
                USR_id,
                EPCI_id,
                DOM_id,
                search
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
}

export async function fetchTechTickets(offset = 0, limit = 50, orderBy, orderDirection, CLI_id, ETA_id, EPCI_id, USR_id, DOM_id, search) {
    try {
        const response = await axios.get('/tik/tech/', {
            params: {
                offset,
                limit,
                orderBy,
                orderDirection,
                CLI_id,
                ETA_id,
                USR_id,
                EPCI_id,
                DOM_id,
                search
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
}

export async function fetchTicketsWithoutCli() {
    try {
        const response = await axios.get('/tik/whitoutCli');
        return response.data;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
}

export async function getCountTicketsWithoutCli() {
    try {
        const response = await axios.get('/tik/countWhitoutCli');
        return response.data;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
}

export async function updateTicket(id, values) {
    try {
        const response = await axios.put(`/tik/${id}`, values);
        return response.data;
    } catch (error) {
        console.error('Erreur lors de la mise à jour du ticket');
        throw error;
    }
}

export async function postTicket(values, USR_id) {
    const tik = await axios.post('/tik/', { ...values, USR_id });
    return tik.data.tik;
}

export async function setupWPtik(values) {
    const tik = await axios.post(`/tik/setupWPtik/`, values);
    return tik.data.TIK_id;
}

export async function clotureTicket(id) {
    const tik = await axios.post(`/tik/cloture/${id}`);
    return tik.data;
}

export async function cancelTicket(id) {
    const response = await axios.post(`/tik/cancel/${id}`);
    return response.data;
}
export async function isCloturable(id) {
    const response = await axios.get(`/tik/checkCloturable/${id}`);
    return response.data;
}
