import React, { Suspense, useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setDisplay } from './store/slices/authSlice';
import { NavigationProvider } from './context/NavigationContext'; 
import ROLES from './constants/roles';
import 'bootstrap/dist/css/bootstrap.min.css';

import WPFormSuccess from './pages/WpFormSuccess';
import NavigationMenu from './components/navigation/NavMenu';
import Logout from './components/auth/Logout';
import PrivateRoute from './components/navigation/PrivateRoute';
import LoadingSpinner from './components/shared/LoadingSpinner';

const TicketDetail = React.lazy(() => import('./containers/TicketDetailContainer'));
const ResetPass = React.lazy(() => import('./containers/ResetPasswordContainer'));
const Accueil = React.lazy(() => import('./containers/AccueilContainer'));
const TicketSetUp = React.lazy(() => import('./containers/TicketSetUpContainer'));
const TicketsListe = React.lazy(() => import('./containers/TicketListContainer'));
const ChantierList = React.lazy(() => import('./containers/ChantierListContainer'));
const ChantierDetail = React.lazy(() => import('./containers/ChantierDetailContainer'));
const LoginPage = React.lazy(() => import('./containers/LoginContainer'));
const UserDetail = React.lazy(() => import('./containers/UserDetailContainer'));
const UsersTablePage = React.lazy(() => import('./containers/UsersListContainer'));
const ClientListe = React.lazy(() => import('./containers/ClientsListContainer'));
const ClientDetail = React.lazy(() => import('./containers/ClientDetailContainer'));
const BackOffice = React.lazy(() => import('./containers/BackOfficeContainer'));

function App() {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const dispatch = useDispatch();

  const determineDisplayType = (width) => (width <= 768 ? 'mobile' : 'pc');

  useEffect(() => {
    const handleResize = () => {
      const currentWidth = window.innerWidth;
      const displayType = determineDisplayType(currentWidth);
      dispatch(setDisplay(displayType));
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [dispatch]);

  return (
    <NavigationProvider>
      {isAuthenticated && <NavigationMenu />}
      <Suspense fallback={<LoadingSpinner />}>
        <Routes>
          <Route path="/wpFormSuccess/:TIK_num" element={<WPFormSuccess />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/accueil" element={<PrivateRoute allowedRoles={ROLES.INTERN}><Accueil /></PrivateRoute>} />
          <Route path="/users" element={<PrivateRoute allowedRoles={ROLES.MANAGEMENT}><UsersTablePage /></PrivateRoute>} />

          <Route path="/user/:id" element={<PrivateRoute allowedRoles={ROLES.INTERN} sameId={true}><UserDetail /></PrivateRoute>} />
          
          <Route path="/tickets" element={<PrivateRoute allowedRoles={ROLES.INTERN}><TicketsListe /></PrivateRoute>} />
          <Route path="/tickets/:id" element={<PrivateRoute allowedRoles={ROLES.INTERN}><TicketDetail /></PrivateRoute>} />
          <Route path="/chantiers/" element={<PrivateRoute allowedRoles={ROLES.MANAGEMENT}><ChantierList /></PrivateRoute>} />
          <Route path="/chantiers/:id" element={<PrivateRoute allowedRoles={ROLES.INTERN}><ChantierDetail /></PrivateRoute>} />
          <Route path="/ticketSetup/:id" element={<PrivateRoute allowedRoles={ROLES.MANAGEMENT}><TicketSetUp /></PrivateRoute>} />
          <Route path="/clients" element={<PrivateRoute allowedRoles={ROLES.MANAGEMENT}><ClientListe /></PrivateRoute>} />
          <Route path="/client/:id" element={<PrivateRoute allowedRoles={ROLES.MANAGEMENT}><ClientDetail /></PrivateRoute>} />
          <Route path="/backOffice" element={<PrivateRoute allowedRoles={[ROLES.ADMIN]}><BackOffice /></PrivateRoute>} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/resetPass/:token" element={<ResetPass />} />
          <Route path="*" element={isAuthenticated ? <Navigate to="/accueil" replace /> : <Navigate to="/login" replace />} />
        </Routes>
      </Suspense>
    </NavigationProvider>
  );
}

export default App;