import { useState, useEffect } from 'react';
import { getCountTicketsWithoutCli } from '../api/ticket';

const useCountTicketsWithoutCli = () => {
    const [count, setCount] = useState(0);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchCount = async () => {
            try {
                const response = await getCountTicketsWithoutCli();
                setCount(response.count);
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchCount();
    }, []);

    return { count, loading, error };
};

export default useCountTicketsWithoutCli;