import React from 'react';
import { Navigate, useParams } from 'react-router-dom';
import ROLES from '../../constants/roles';
import { useSelector } from 'react-redux';

const PrivateRoute = ({ children, allowedRoles = [], sameId = false }) => {
  const { id } = useParams();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const userInfo = useSelector((state) => state.auth.userInfo);

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  // Vérifie si l'utilisateur a un rôle autorisé
  const hasAccess = userInfo && allowedRoles.includes(userInfo.ROE_id);

  const canAccessData =
    ROLES.isManager(userInfo.ROE_id) ||
    (!ROLES.isManager(userInfo.ROE_id) && userInfo.USR_id === parseInt(id));

  if (!hasAccess || (sameId && !canAccessData)) {
    return <Navigate to="/accueil" replace />;
  }

  return children;
};

export default PrivateRoute;
