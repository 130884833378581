import axios from './axiosConfig';

export const fetchCotByCli = async (id) => {
    try {
        const response = await axios.get(`/cot/getCotByCli/${id}`);
        return response.data;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};

export const fetchContactByCli = async (cliId, domId) => {
    try {
        const response = await axios.get(`/cot/getContact/${cliId}`, {
            params: {
                DOM_id: domId
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};

export const fetchCotByCliAndDdom = async (cliId, domId) => {
    try {
        const response = await axios.get(`/cot/getCotByCliAndDdom/${cliId}/${domId}`);
        const formattedContrat = response.data.map(contrat => ({
            value: contrat.COT_id,
            label: contrat.COT_libelle
        }));
        return formattedContrat;
    } catch (error) {
        console.error('Error:', error);
        return [];
    }
};

export const postCot = async (values) => {
    try {
        const response = await axios.post(`/cot/`, values);
        return response.data;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};