const ROLES = {
    // Rôles individuels
    TECH: 1,
    CLIENT: 2,
    GEST: 3,
    ADMIN: 4,

    // Collection de rôles internes
    INTERN: [1, 3, 4],      // Regroupe TECH, GEST et ADMIN
    MANAGEMENT: [3, 4],     // Regroupe GEST et ADMIN

    // Helpers pour vérifier les rôles
    
    isIntern: (roleId) => [1, 3, 4].includes(roleId),
    isManager: (roleId) => [3, 4].includes(roleId),
};

export default ROLES;
